<template>
  <div v-if="isPlainLayout" id="app">
    <router-view class="main-content"></router-view>
  </div>
  <div v-else id="app">
    <header-component :class="{ transparent: isTransparent }" />
    <router-view
      class="main-content"
      :class="{ 'padding-top': isNotHome }"
    ></router-view>
    <footer-component />
    <toast-component v-if="needToast" />
  </div>
</template>

<script>
import FooterComponent from './components/FooterComponent';
import HeaderComponent from '../league/components/HeaderComponent';
import throttle from 'lodash/throttle';
import { mapState } from 'vuex';
import ToastComponent from '../components/base-components/Toast';

export default {
  name: 'App',
  data() {
    return {
      isTransparent: false,
    };
  },
  components: {
    ToastComponent,
    HeaderComponent,
    FooterComponent,
  },
  methods: {
    handleScroll: throttle(
      function () {
        if (this.$route.name && String(this.$route.name) !== 'home') {
          this.isTransparent = false;
          return;
        }
        this.isTransparent = window.scrollY < window.innerHeight - 70;
      },
      200,
      {
        leading: true,
      }
    ),
  },
  computed: {
    ...mapState({
      channelSettings: (state) => state.channel.settings,
      needToast: (state) => state.settings.toastList?.length,
    }),
    isNotHome() {
      return this.$route.name !== 'home';
    },
    isPlainLayout() {
      return this.$route.meta.plainLayout || false;
    },
  },
  metaInfo() {
    return {
      title: this.$route.meta.title ? this.$route.meta.title : '',
      titleTemplate: this.channelSettings
        ? '%s ' + this.channelSettings.title
        : '%s  Visaic',
      htmlAttrs: {
        lang: 'en',
      },
      meta: [
        {
          vmid: 'description',
          name: 'description',
          content: this.channelSettings?.description,
        },
      ],
    };
  },
};
</script>

<style lang="scss">
@import 'src/assets/css/colors';
@import 'src/assets/css/mixins';
@import 'src/assets/css/fonts';

.grecaptcha-badge {
  visibility: hidden;
}
html,
body {
  font-family: var(--settings-body-font-family), sans-serif;
}

#app {
  font-family: var(--settings-body-font-family);
  font-weight: 900;
  font-style: italic;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: $text-primary;
  max-width: 100vw;
  min-height: 100vh;
  font-size: 1rem;
  display: flex;
  flex-direction: column;
  background-color: var(--settings-body-background);

  .padding-top {
    padding-top: 80px;
  }
  .main-content {
    min-height: 75vh;
    flex-grow: 1;
  }
}
.main-content {
  background-color: var(--settings-body-background);
  color: var(--settings-body-text-color);
}
body {
  font-size: 16px;
  text-transform: uppercase;
}
.main-container {
  max-width: 1140px;
  margin: 0 auto;
  width: 100%;
  @include media-min(max-container) {
    max-width: 1920px;
  }
  @include media-max(lg) {
    max-width: 960px;
  }
  @include media-max(md) {
    max-width: 720px;
  }
  @include media-max(sm) {
    max-width: 540px;
  }
  @include media-max(xs) {
    max-width: 90vw;
  }
}

select,
option {
  font-family: var(--settings-body-font-family), sans-serif;
}
</style>
